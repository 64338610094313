
























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import topicsJSON from "@/resources/topics.json";
import questionsJSON from "@/resources/questions.json";
import wordsJSON from "@/resources/words.json";
import { TranslateResult } from "vue-i18n";

@Component({
	components: {},
})
export default class ChordProgressionGenerator extends Vue {
	chords = new Map<string, Array<string>>();
	modes = new Array<string>();
	sources = new Array<string>();
	key = "Beliebig";
	mode = "Zufall";
	source = "Wörter";
	numberOfChords: string = "4";
	generatedChordsArray = new Array<string>();
	generatedLyricsArray = new Array<string>();
	keyToUse = this.key;
	currentKey: string = "";
	topics = topicsJSON;
	words = wordsJSON;
	questions = questionsJSON;
	isWelcomeModalActive = true;
	isChordsModalActive = false;
	isLyricsModalActive = false;
	disableWelcomeModal = false;
	oppositeLocale = "en";

	boredCounter: number = 0;
	isGameModalActive = false;
	isStartScreenActive = false;
	isGameScreenActive = false;
	isScoreScreenActive = true;
	player: string = "G";
	playerPosition: number = 0;
	playingField: Array<Array<string>> = [];
	lastLine = this.playingField.length - 1;
	staff: string = "¨©©©©©©®";

	score: number = 0;
	lifes: Array<string> = ["B", "B", "B"];
	notes: Array<string> = ["w", "h", "q", "e", "s"];
	rests: Array<string> = ["W", "H", "Q ", "E ", "S "];
	maxRests: number = 6;
	lastLineWasBlank = true;
	addedLines: number = 0;
	difficulty: number = 2;
	speed: number = 1000;
	gameInterval: number = 0;
	togglePauseResumeLable = "Pause";
	highscore: number = 0;
	newHighscore = false;
	isLoading: boolean = true;

	// GAME

	private increaseBoredCounter() {
		this.boredCounter++;

		if (this.boredCounter >= 10) {
			this.resetGame();
			this.isGameModalActive = true;
			console.log(this.playingField);
		}
	}

	private changeScreen(screen: string) {
		this.isStartScreenActive = false;
		this.isGameScreenActive = false;
		this.isScoreScreenActive = false;

		switch (screen) {
			case "startScreen":
				this.isStartScreenActive = true;
				break;
			case "gameScreen":
				this.isGameScreenActive = true;
				this.startGame();
				break;
			case "scoreScreen":
				this.stopGame();
				this.checkHighscore();
				this.isScoreScreenActive = true;
				window.setTimeout(this.enableLoadingBtn, 2500);
				break;

			default:
				break;
		}
	}

	private enableLoadingBtn() {
		this.isLoading = false;
	}

	private closeGameModal() {
		this.stopGame();
		this.isGameModalActive = false;
		this.boredCounter = 0;
	}

	private openGameModal() {
		this.isGameModalActive = true;
	}

	private choosePlayer(chosenPlayer: string) {
		this.player = chosenPlayer;
		this.changeScreen("gameScreen");
	}

	private startGame() {
		this.playingField[this.lastLine].splice(this.playerPosition, 1, this.player);
		this.gameInterval = window.setInterval(this.updatePlayingField, this.speed);
	}

	private stopGame() {
		window.clearInterval(this.gameInterval);
	}

	private togglePauseResume() {
		switch (this.togglePauseResumeLable) {
			case "Pause":
				{
					this.stopGame();
					this.togglePauseResumeLable = "Fortsetzen";
				}
				break;

			case "Fortsetzen":
				{
					this.startGame();
					this.togglePauseResumeLable = "Pause";
				}
				break;

			default:
				break;
		}
	}

	private updatePlayingField() {
		const oldPlayerPosition = this.playerPosition;

		this.checkCollision(this.lastLine - 1, oldPlayerPosition);

		this.addLine();
		this.playingField[this.lastLine][oldPlayerPosition] = this.player;
	}

	private increaseDifficulty() {
		if (this.addedLines > 25) {
			if (this.speed > 500) {
				this.speed -= 100 - this.difficulty * 10;
				this.stopGame();
				this.startGame();
			}
			if (this.difficulty < 8) {
				this.difficulty += 1;
			}

			this.addedLines = 0;
		}
	}

	private addLine() {
		const newLine: Array<string> = [];

		this.increaseDifficulty();

		while (newLine.length < 5) {
			newLine.unshift("  ");
		}

		if (this.lastLineWasBlank) {
			for (let index = 0; index < this.difficulty && index < this.maxRests; index++) {
				newLine[this.getRandomInt(5)] = this.rests[this.getRandomInt(this.rests.length)];
			}
			newLine[this.getRandomInt(5)] = this.notes[this.getRandomInt(this.notes.length)];
			this.lastLineWasBlank = false;
		} else {
			this.lastLineWasBlank = true;
		}

		this.playingField.unshift(newLine);
		this.addedLines += 1;
		this.playingField.length = 5;
		this.playingField.splice(this.lastLine + 1, 1);
	}

	private getRandomInt(max: number) {
		return Math.floor(Math.random() * max);
	}

	private moveLeft() {
		if (this.playerPosition > 0) {
			this.checkCollision(this.lastLine, this.playerPosition - 1);
			this.playingField[this.lastLine].splice(this.playerPosition - 1, 2, this.player, "  ");
			this.playerPosition -= 1;
		}
	}

	private moveRight() {
		if (this.playerPosition < 4) {
			this.checkCollision(this.lastLine, this.playerPosition + 1);
			this.playingField[this.lastLine].splice(this.playerPosition, 2, "  ", this.player);
			this.playerPosition += 1;
		}
	}

	private checkCollision(y: number, x: number) {
		//check for notes
		if (this.notes.includes(this.playingField[y][x])) {
			this.score += 50 * this.difficulty;
		}

		//check for rests
		if (this.rests.includes(this.playingField[y][x])) {
			this.lifes.length = this.lifes.length - 1;
			//check if lost
			if (this.lifes.length === 0) {
				this.changeScreen("scoreScreen");
			}
		}
	}

	private checkHighscore() {
		if (localStorage.getItem("highscore")) {
			this.highscore = Number(localStorage.getItem("highscore"));
		}
		if (this.highscore < this.score) {
			this.highscore = this.score;
			this.newHighscore = true;
			localStorage.setItem("highscore", this.score.toString());
		}
	}

	private resetGame() {
		this.playerPosition = 0;
		this.playingField = [
			["  ", "  ", "  ", "  ", "  "],
			["  ", "  ", "  ", "  ", "  "],
			["  ", "  ", "  ", "  ", "  "],
			["  ", "  ", "  ", "  ", "  "],
			["  ", "  ", "  ", "  ", "  "],
		];

		this.lastLine = this.playingField.length - 1;
		this.score = 0;
		this.lifes = ["B", "B", "B"];
		this.lastLineWasBlank = true;
		this.addedLines = 0;
		this.difficulty = 2;
		this.speed = 1000;
		this.togglePauseResumeLable = "Pause";
		this.highscore = 0;
		this.newHighscore = false;
		this.changeScreen("startScreen");
		this.isLoading = true;
	}

	private created(): void {
		this.chords.set("C", ["C", "Dm", "Em", "F", "G", "Am"]);
		this.chords.set("D", ["D", "Em", "F#m", "G", "A", "Hm"]);
		this.chords.set("E", ["E", "F#m", "G#m", "A", "H", "C#m"]);
		this.chords.set("F", ["F", "Gm", "Am", "B", "C", "Dm"]);
		this.chords.set("G", ["G", "Am", "Hm", "C", "D", "Em"]);
		this.chords.set("A", ["A", "Hm", "C#m", "D", "E", "F#m"]);
		this.chords.set("H", ["H", "C#m", "D#m", "E", "F#", "G#m"]);

		this.modes.push("Kontur");
		this.modes.push("Zufall");
		this.modes.push("Strebung");

		this.sources.push("Fragen");
		this.sources.push("Themen");
		this.sources.push("Wörter");

		this.checkDisabledWelcomeModal();
	}

	get generatedChords(): string {
		if (this.generatedChordsArray.length >= 8) {
			this.generatedChordsArray.splice(4, 0, "\n");
		}

		if (this.generatedChordsArray.length === 13) {
			this.generatedChordsArray.splice(-4, 0, "\n");
		}

		return this.generatedChordsArray.join(" ");
	}

	get generatedLyrics(): string {
		return this.generatedLyricsArray.join(", ");
	}

	private generateLyrics(): void {
		this.generatedLyricsArray = [];
		this.sourceCollection();
	}

	private generateChords(): void {
		this.generatedChordsArray = [];

		if (this.key === "Beliebig") {
			const keyArray = Array.from(this.chords.keys());
			this.keyToUse = keyArray[Math.floor(Math.random() * 7)];
		} else {
			this.keyToUse = this.key;
		}
		this.modeCollection();
	}

	private sourceCollection(): void {
		switch (this.source) {
			case "Fragen":
				const numeral1 = Math.floor(Math.random() * this.questions.length);

				this.generatedLyricsArray.push(this.questions[numeral1]);
				break;

			case "Wörter":
				for (let i = 0; i < 3; i++) {
					const numeral2 = Math.floor(Math.random() * this.words.length);

					this.generatedLyricsArray.push(this.words[numeral2]);
				}
				break;

			case "Themen":
				const numeral3 = Math.floor(Math.random() * this.topics.length);

				this.generatedLyricsArray.push(this.topics[numeral3]);
				break;
			default:
				break;
		}
	}

	private modeCollection(): void {
		switch (this.mode) {
			case "Zufall":
				for (let i = 0; i < parseInt(this.numberOfChords); i++) {
					const numeral = Math.floor(Math.random() * 6);
					this.generatedChordsArray.push(this.chords.get(this.keyToUse)![numeral]);
				}
				break;

			case "Strebung":
				this.tendencyMode();
				break;

			case "Kontur":
				this.contourMode();
				break;
			default:
				break;
		}
	}

	private contourMode(): void {
		this.generatedChordsArray.push(this.chords.get(this.keyToUse)![0]);
		for (let i = 0; i < parseInt(this.numberOfChords) - 2; i++) {
			const numeral = Math.floor(Math.random() * 6);
			this.generatedChordsArray.push(this.chords.get(this.keyToUse)![numeral]);
		}
		const rand = Math.floor(Math.random() * 2);
		let numeral = 0;
		if (rand === 1) {
			numeral = 3;
		} else {
			numeral = 4;
		}
		this.generatedChordsArray.push(this.chords.get(this.keyToUse)![numeral]);
	}

	private tendencyMode(): void {
		let currentChord = this.chords.get(this.keyToUse)![0];
		let numeral = Math.floor(Math.random() * 6);
		for (let i = 0; i < parseInt(this.numberOfChords); i++) {
			switch (currentChord) {
				case this.chords.get(this.keyToUse)![0]:
					this.generatedChordsArray.push(currentChord);
					numeral = Math.floor(Math.random() * 6);
					break;
				case this.chords.get(this.keyToUse)![1]:
					this.generatedChordsArray.push(currentChord);
					numeral = 4;
					break;
				case this.chords.get(this.keyToUse)![2]:
					this.generatedChordsArray.push(currentChord);
					numeral = 5;
					break;
				case this.chords.get(this.keyToUse)![3]:
					this.generatedChordsArray.push(currentChord);
					const rand = Math.floor(Math.random() * 2);
					if (rand === 1) {
						numeral = 0;
					} else {
						numeral = 4;
					}
					break;
				case this.chords.get(this.keyToUse)![4]:
					this.generatedChordsArray.push(currentChord);
					numeral = 0;
					break;
				case this.chords.get(this.keyToUse)![5]:
					this.generatedChordsArray.push(currentChord);
					numeral = 1;
					break;

				default:
					break;
			}
			currentChord = this.chords.get(this.keyToUse)![numeral];
		}
	}

	private changeMode(newMode: string): void {
		this.mode = newMode;
	}

	private changeKey(newKey: string): void {
		this.key = newKey;
	}

	private changeNumberOfChords(newNumberOfChords: number): void {
		this.numberOfChords = newNumberOfChords.toString();
	}

	private changeSource(newSource: string): void {
		this.source = newSource;
	}

	private generateResult() {
		this.generateLyrics();
		this.generateChords();
		this.currentKey = this.keyToUse;
	}

	private toggleLanguage() {
		this.oppositeLocale = this.$i18n.locale;
		this.$i18n.locale = this.$i18n.locale === "de" ? "en" : "de";
	}

	private closeWelcomeModal() {
		this.isWelcomeModalActive = false;
		if (this.disableWelcomeModal === true) {
			localStorage.setItem("disabledWelcomeModal", "true");
		}
	}

	private checkDisabledWelcomeModal() {
		if (localStorage.getItem("disabledWelcomeModal")) {
			if (localStorage.getItem("disabledWelcomeModal") === "true") {
				this.isWelcomeModalActive = false;
			}
		}
	}
}
