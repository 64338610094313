import "@/registerServiceWorker";

import buefy from "buefy";
import Vue from "vue";

import ChordProgressionGenerator from "./ChordProgressionGenerator.vue";
import i18n from "./i18n";

Vue.config.productionTip = false;

Vue.use(buefy);

new Vue({
	i18n,
	render: (h) => h(ChordProgressionGenerator),
}).$mount("#app");
